import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>HİZMETLERİMİZ</h2>
          <p>
          ZiPrint olarak, ithal ettiğimiz yüksek kaliteli muadil tonerlerle baskı çözümlerinde en iyiyi sunuyoruz. Müşterilerimize yazıcı performansını artıran, tam uyumlu ve ekonomik baskı çözümleri sağlarken, teknik destek ve çevre dostu üretim anlayışımızla da öne çıkıyoruz. Sunduğumuz hizmetlerle, yazıcılarınızda kesintisiz ve verimli bir deneyim yaşamanız için yanınızdayız.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
